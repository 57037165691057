/**
 * @file Project behaviours
 * @author Tom Jenkins tom@itsravenous.com
 */

var ready = require('doc-ready');

ready(function () {
	// Get project
	var project = document.querySelector('.project');
	if (project) {
		// Get project nav
		var projectNav = project.querySelector('.project-back-next');
		// Get project text
		var projectText = project.querySelector('.text-art-block-text');
		// Get site header
		var header = document.querySelector('.header');
		// Get site footer
		var footer = document.querySelector('.footer');
		// Determine page height and show threshold
		var pageHeight = document.body.offsetHeight;
		var threshold = (header.offsetHeight + projectText.offsetHeight);

		/**
		 * Fix nav to RHS of project, bottom of page (cheating here by knowing about the footer and the way the page is centred)
		 */
		function fixNav () {
			projectNav.style.right = ((document.body.offsetWidth - project.offsetWidth) / 2) + 'px';
			projectNav.style.bottom = footer.offsetHeight + 32 + 'px';
		}
		window.addEventListener('resize', fixNav);
		fixNav();

		/**
		 * Listen for scrolling and show or hide nav
		 */
		window.addEventListener('scroll', function (e) {
			var y = e.pageY || document.documentElement.scrollTop || document.body.scrollTop;
			if (y > threshold) {
				projectNav.className += ' is-visible';
			} else {
				projectNav.className = projectNav.className.replace(/is-visible/g, '');
			}
		});
	}
});