/**
 * @file Behaviours for the project list component
 * @author Tom Jenkins tom@itsravenous.com
 */

var es5shim = require('es5-shim');

/**
 * @constructor {BlockList}
*/
var BlockList = function(el, options) {
	this.el = el;
	this.options = options || {};
	this.filterable = this.options.filterable || this.el.getAttribute('data-filterable');
	this.autoArrange = this.options.autoArrange || this.el.getAttribute('data-autoarrange');
	this.blockSelector = this.options.blockSelector || this.el.getAttribute('data-block-selector');

	/**
	 * The container for matching elements when filtered (or all when not)
	 * {HTMLElement}
	 */
	this.matchingEl = this.filterable ? this.el.querySelector('.blocks-matching') : this.el;

	/**
	 * The container for non-matching elements when filtered
	 * {HTMLElement}
	 */
	this.nonMatchingEl = this.el.querySelector('.blocks-non-matching');

	if (this.autoArrange) {
		this.createGroups();
	}

	if (this.filterable) {
		this.createFilters();
	}

	// Apply filter from url if present
	this.filterFromURL();
}

BlockList.prototype = {

	/**
	 * Discovers matching child blocks
	 * @return {Array}
	 */
	getMatchingBlocks: function () {
		var nodes = this.blockSelector ? this.matchingEl.querySelectorAll(this.blockSelector) : this.matchingEl.childNodes;
		var blocks = [];
		// Filter out text nodes
		for (var i = 0; i < nodes.length; i++) {
			if (nodes[i].nodeType == 1) {
				blocks.push(nodes[i]);
			}
		}
		return blocks;
	},

	/**
	 * Discovers all child blocks
	 * @return {Array}
	 */
	getBlocks: function () {
		var nodes = this.blockSelector ? this.el.querySelectorAll(this.blockSelector) : this.el.childNodes;
		var blocks = [];
		// Filter out text nodes
		for (var i = 0; i < nodes.length; i++) {
			if (nodes[i].nodeType == 1) {
				blocks.push(nodes[i]);
			}
		}
		return blocks;
	},

	/**
	 * Creates the filter UI
	 */
	createFilters: function () {
		this.filterEl = document.createElement('div');
		this.filterEl.className = 'blocks-filters';
		var all  = document.createElement('a');
		all.href = '#';
		all.className = 'is-active';
		all.innerHTML = 'All';
		this.filterEl.appendChild(all);

		var imgs = this.getBlocks(),
			cats,
			catSlugs,
			catsUnique = [],
			filters = [];
		for (var i = 0; i < imgs.length; i++) {
			cats = imgs[i].getAttribute('data-cats').split(',').map(function (cn) {
				return cn.trim();
			});
			catSlugs = imgs[i].getAttribute('data-cat-slugs').split(',').map(function (cn) {
				return cn.trim();
			});
			for (var j = 0; j < cats.length; j++) {
				if (cats[j].length && catsUnique.indexOf(cats[j]) == -1) {
					// Create filter
					var filter = document.createElement('a');
					filter.href = '#'+catSlugs[j];
					filter.innerHTML = cats[j];
					filter.setAttribute('data-cat-slug', catSlugs[j]);
					filters.push(filter);
					catsUnique.push(cats[j]);
				}
			}
		}		

		// Sort filters and add to filter list
		filters.sort(function (a, b) {
			if (a.innerHTML < b.innerHTML) {
				return -1;
			} else if (a.innerHTML > b.innerHTML) {
				return 1;
			} else {
				return 0;
			}
		});

		filters.forEach(function (filter) {
			this.filterEl.appendChild(filter);
		}.bind(this));

		// Add filter list to top of root element
		this.el.insertBefore(this.filterEl, this.matchingEl);

		// Listen for selection
		this.filterEl.addEventListener('click', function (e) {
			if (e.target.nodeName.toLowerCase() == 'a') {
				var links = this.filterEl.querySelectorAll('a');
				for (var i = 0; i < links.length; i ++) {
					links[i].className = links[i].className.replace(/is-active/g, '');
				}
				var link = e.target;
				link.className += ' is-active';
				var cat = link.getAttribute('href').substr(1);
				if (cat) {
					this.filter('[data-cat-slugs*="'+cat+'"]');
				} else {
					this.filter('*');
				}
			}
		}.bind(this));
	},

	/**
	 * Filters based on the current URL
	 */
	filterFromURL: function () {
		// Get filter
		var filter = window.location.hash.substr(1);

		if (filter && filter.length) {
			// Filter list
			this.filter('[data-cat-slugs*="'+filter+'"]');

			// Click relevant filter
			var event = document.createEvent('HTMLEvents');
			event.initEvent('click', true, false);
			this.filterEl.querySelector('[data-cat-slug="'+filter+'"]').dispatchEvent(event);
		}
	},

	/**
	 * Filters the project list based on a selector
	 * @param {String} selector to match against
	 */
	filter: function (selector) {
		this.el.className += ' is-filtering';
		// Fade out and filter while hidden 
		setTimeout(function () {
			this.reset();
			var imgs = this.getBlocks();
			for (var i = 0; i < imgs.length; i ++) {
				imgs[i].parentNode.removeChild(imgs[i]);
				if (imgs[i].matches && imgs[i].matches(selector) || (imgs[i].msMatchesSelector && imgs[i].msMatchesSelector(selector)) || (imgs[i].webkitMatchesSelector && imgs[i].webkitMatchesSelector(selector))) {
					this.matchingEl.appendChild(imgs[i]);
				} else {
					this.nonMatchingEl.appendChild(imgs[i]);
				}
			}
			this.createGroups();

			// Fade back in
			setTimeout(function () {
				this.el.className = this.el.className.replace(/ is-filtering/g, '');
			}.bind(this), 10);
		}.bind(this), 1000);
	},

	/**
	 * Resets the project list back to a vanilla list of images, ready for createGroups to be run again
	 */
	reset: function () {
		var imgs = this.getBlocks();
		for (var i = 0; i < imgs.length; i ++) {
			imgs[i].parentNode.removeChild(imgs[i]);
		}
		this.matchingEl.innerHTML = '';
		this.nonMatchingEl.innerHTML = '';
		for (var i = 0; i < imgs.length; i ++) {
			this.matchingEl.appendChild(imgs[i]);
		}
	},

	/**
	 * Groups images together in a nice mosaic
	 * This function makes some very sweeping assumptions:
	 * - Three heights of image
	 * - Five widths of image
	 * - Limited, preset combinations of images to form rows
	 *
	 */
	createGroups: function () {
		var imgs = this.getMatchingBlocks();

		// Sort images by height
		imgs.sort(function (img1, img2) {
			var h1 = img1.getAttribute('data-h');
			var h2 = img2.getAttribute('data-h');
			if (h1 > h2) {
				return 1;
			} else if (h1 < h2) {
				return -1;
			} else {
				return 0;
			}
		});

		// Pair up same-width short images that need stacking and group as one standard-height image
		var w1,
			w2,
			h1,
			h2,
			img1,
			img2,
			shorts = {
				w1: [],
				w2: [],
				w3: [],
				w4: [],
				w5: []
			},
			stack;
		for (var i = 0; i < imgs.length; i++) {
			img1 = imgs[i]
			w1 = img1.getAttribute('data-w');
			h1 = img1.getAttribute('data-h');
			if (h1 = 1 && i < imgs.length - 1) {
				img2 = imgs[i + 1];
				w2 = img2.getAttribute('data-w');
				h2 = img2.getAttribute('data-h');
				if (w2 == w1 && h2 == h1) {
					shorts['w'+w2].push([img1, img2]);
					imgs[i] = null;
					imgs[i + 1] = null;
					// Create wrapper for stack
					stack = document.createElement('div');
					stack.className = 'blocks-stack';
					stack.setAttribute('data-w', w1);
					stack.setAttribute('data-h', 3);
					// Add images to stack
					stack.appendChild(img1);
					stack.appendChild(document.createTextNode(' ')); // In case HTML is minified, we need whitespace for justification to work
					stack.appendChild(img2);
					imgs.push(stack);
					// Move on
					i ++;
				}
			}
		}

		imgs = imgs.filter(function (img) {
			return img != null;
		});

		// Pair images based on width
		var pairs = [];
		for (var i = 0; i < imgs.length; i++) {
			img1 = imgs[i];
			if (img1 != null) {
				w1 = parseInt(img1.getAttribute('data-w'));
				h1 = parseInt(img1.getAttribute('data-h'));
				for (var j = 0; j < imgs.length; j++) {
					img2 = imgs[j];
					if (j != i && img2 != null) {
						w2 = parseInt(img2.getAttribute('data-w'));
						h2 = parseInt(img2.getAttribute('data-h'));
						if (h1 == h2 && w1 + w2 == 6) {
							pairs.push([img1, img2]);
							imgs[i] = null;
							imgs[j] = null;
							break;
						}
					}
				}
			}
		}

		imgs = imgs.filter(function (img) {
			return img != null;
		});

		// Add the pairs to the container
		var pair,
			row;
		for (var k = 0; k < pairs.length; k++) {
			pair = pairs[k];
			row = document.createElement('div');
			row.className = 'blocks-row';
			row.appendChild(pair[0]);
			row.appendChild(document.createTextNode(' ')); // In case HTML is minified, we need whitespace for justification to work
			row.appendChild(pair[1]);
			this.matchingEl.appendChild(row);
		}

		// Convert orphan stacks to pair rows
		var stackImgs;
		for (var l = 0; l < imgs.length; l++) {
			img = imgs[l];
			// If leftover is a stack, and widths add up to 6, convert to normal pair row
			if (img.className == 'blocks-stack' && img.getAttribute('data-w') == 3) {
				img.className = 'blocks-row';
				stackImgs = img.getElementsByTagName('img');
				stackImgs[0].setAttribute('data-w', 3);
				stackImgs[1].setAttribute('data-w', 3);
				this.matchingEl.appendChild(img);
				imgs[l] = null;
			}
		}

		imgs = imgs.filter(function (img) {
			return img != null;
		});

		// See if we can make some partial-width rows
		var o,
			tw,
			partial = [],
			tmpPartials = [],
			realWidths = [
				null,
				370,
				470,
				570,
				670,
				770
			],
			rowWidth = 1140;
		for (var n = 0; n < imgs.length; n ++) {
			img1 = imgs[n];
			if (img1 != null) {
				w1 = parseInt(img1.getAttribute('data-w'));
				h1 = parseInt(img1.getAttribute('data-h'));
				o = n + 1;
				tw = realWidths[w1];
				tmpPartials = [img1];
				while (tw < rowWidth && o < imgs.length) {
					img2 = imgs[o];
					if (img2 != null) {
						w2 = parseInt(img2.getAttribute('data-w'));
						h2 = parseInt(img2.getAttribute('data-h'));
						if (tw + realWidths[w2] <= rowWidth && h1 == h2) {
							tw = tw + realWidths[w2];
							tmpPartials.push(img2);
							imgs[o] = null;
						}
					}
					o++;
				}
				if (tmpPartials.length > 1) {
					imgs[n] = null;
					partial = document.createElement('div');
					partial.className = 'blocks-row';
					// Tag non-filled rows for special styling
					if (tw < realWidths[1] * 3) {
						partial.className += ' blocks-row--partial';
					}

					for (var p = 0; p < tmpPartials.length; p++) {
						partial.appendChild(tmpPartials[p]);
						partial.appendChild(document.createTextNode(' ')); // In case HTML is minified, we need whitespace for justification to work
					}
					this.matchingEl.appendChild(partial);
				}
			}
		}

		imgs = imgs.filter(function (img) {
			return img != null;
		});

		// Add unpaired images
		for (var m = 0; m < imgs.length; m++) {
			img1 = imgs[m];
			w1 = img1.getAttribute('data-w');
			if (w1 < 6) {
				h1 = img1.getAttribute('data-h');
				// Create a blank partner for remaining width
				img2 = document.createElement('div');
				img2.className = 'blocks-blank';
				img2.setAttribute('data-w', 6 - w1);
				img2.setAttribute('data-h', h1);
			} else {
				img2 = null;
			}

			row = document.createElement('div');
			row.className = 'blocks-row';
			row.appendChild(img1);
			if (img2) {
				row.appendChild(document.createTextNode(' ')); // In case HTML is minified, we need whitespace for justification to work
				row.appendChild(img2);
			}

			// Add to container
			this.matchingEl.appendChild(row);

			// Remove form list
			imgs[m] = null;
		}

		// Sort rows randomly
		var rows = this.matchingEl.querySelectorAll('.blocks-row'),
			rowsArr = [];
		// Convert to array
		for (var x = 0; x < rows.length; x++ ) {
			rowsArr.push(rows[x]);
		}
		rowsArr = rowsArr.sort(function() {
			return Math.random() - .5;
		});

		for (var y = 0; y < rowsArr.length; y++) {
			this.matchingEl.removeChild(rowsArr[y]);
			this.matchingEl.appendChild(rowsArr[y]);
		}

		// Finally, flip every other row on its x-axis for a bit of variety
		var rowChildren;
		for (var z = 0; z < rowsArr.length; z = z + 2) {
			rowChildren = rowsArr[z].childNodes;
			for (var a = rowChildren.length - 1; a >= 0; a--) {
				rowsArr[z].appendChild(rowChildren[a]);
			}
		}

	}

}

// Export factory functions
module.exports = {
	/**
	 * Manual creation
	 */
	create: function (el, options) {
		return new BlockList(el, options)
	},

	/**
	 * Creation by scanning DOM
	 */
	activate: function () {
		var els = document.querySelectorAll('.blocks');
		for (var i = 0; i < els.length; i ++) {
			new BlockList(els[0]);
		}
	}
}