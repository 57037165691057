/**
 * @file Project list behaviours
 * @author Tom Jenkins tom@itsravenous.com
 */

var htmlEncode = require('htmlencode').htmlEncode;
var htmlDecode = require('htmlencode').htmlDecode;

function activate() {
	var projectFilters = document.querySelectorAll('.project-list .blocks-filters a');
	var projectLinks = document.querySelectorAll('.project-list .project-teaser a');

	projectFiltersArr = [];
	for (var i = 0; i < projectFilters.length; i++) {
		projectFiltersArr.push(projectFilters[i]);
	}

	projectLinksArr = [];
	for (var i = 0; i < projectLinks.length; i++) {
		projectLinks[i].setAttribute('data-href', projectLinks[i].href);
		projectLinksArr.push(projectLinks[i]);
	}

	projectFiltersArr.forEach(function (filter, i) {
		filter.addEventListener('click', function () {
			var slug = filter.getAttribute('data-cat-slug');
			projectLinksArr.forEach(function (project) {
				// Remove previous category
				project.href = project.href.split('?')[0];
				if (slug && slug.length) {
					// Add current category
					project.href += '?cat=' + encodeURI(slug);
				}
			});
		});
	});

	// Get filter from URL and update links accordingly
	var filter = window.location.hash.substr(1);
	if (filter && filter.length) {
		projectLinksArr.forEach(function (project) {
			// Remove previous category
			project.href = project.href.split('?')[0];
			// Add current category
			project.href += '?cat=' + encodeURI(filter);
		});
	}
}

module.exports = {
	activate: activate
};