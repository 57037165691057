/**
 * @file Simple cross-fading banner 
 * @author Tom Jenkins tom@itsravenous.com
 */

Banner = function (el) {
	var items = el.querySelectorAll('.banner-item');
	items[0].classList.add('banner-item--active');

	var i = 0;

	setInterval(function () {
		items[i].classList.remove('banner-item--active');
		i++
		if (i > items.length - 1) {
			i = 0;
		}
		items[i].classList.add('banner-item--active');	
	}, 6000);
}

module.exports = {
	Banner: Banner,
	activate: function () {
		var banners = document.querySelectorAll('.banner');
		for (var i = 0; i < banners.length; i++) {
			new Banner(banners[i]);
		}
	}
}