/**
 * @file The main JavaScript. Bootstraps the app.
 */

var ready = require('doc-ready');
var BlockList = require('../components/block_list/block-list');
var Banner = require('../components/banner/banner');
var Project = require('../components/project/project');
var ProjectList = require('../components/project_list/project-list');

ready(function () {
	// Activate any block lists
	BlockList.activate();

	// Activate any project lists
	ProjectList.activate()

	// Activate any banners
	Banner.activate();
});